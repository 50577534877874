'use client';

import Image from 'next/image';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

export interface ButtonProps {
  active?: boolean;
  ariaLabel?: string;
  href?: string;
  icon?: string;
  onClick?: (
    e?: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  role?: 'button' | 'link';
  style?: string;
  target?: string;
  title: string;
  type?: 'primary' | 'secondary';
}

const Button = ({
  active = false,
  ariaLabel,
  href,
  icon,
  onClick,
  role = 'button',
  style,
  target,
  title,
  type = 'primary',
}: ButtonProps) => {
  const btnStyles = `cursor-pointer text-center items-center inline-flex py-2 px-4 border-2 border-black text-sm font-bold rounded-theme hover:bg-blue focus-visible:bg-blue hover:drop-shadow-themeY focus-visible:drop-shadow-themeY hover:text-black focus-visible:outline-none focus-visible:text-black`;
  const btnThemeStyles =
    type === 'primary' ? 'bg-white text-black' : 'bg-black text-white';
  const activeStyles = active ? 'bg-blue text-black drop-shadow-themeY' : '';

  const mergedStyles = twMerge(btnStyles, btnThemeStyles, activeStyles, style);

  const content = (
    <>
      {icon && (
        <Image
          className="mr-4"
          alt={`${title} icon`}
          height={16}
          src={icon}
          width={16}
        />
      )}
      {title}
    </>
  );

  if (href && href.startsWith('http')) {
    return (
      <a
        aria-label={ariaLabel || title}
        {...(active ? { 'aria-current': active } : {})}
        href={href}
        className={mergedStyles}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
        }}
        role={role}
        target={target}
        title={ariaLabel || title}
      >
        {content}
      </a>
    );
  }

  return (
    <>
      {href ? (
        <Link
          aria-label={ariaLabel || title}
          aria-current={active}
          href={href}
          className={mergedStyles}
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
          }}
          role={role}
          target={target}
          title={ariaLabel || title}
        >
          {content}
        </Link>
      ) : (
        <button
          aria-label={ariaLabel || title}
          aria-current={active}
          className={mergedStyles}
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
          }}
          role={role}
          title={ariaLabel || title}
        >
          {content}
        </button>
      )}
    </>
  );
};

export default Button;
